import React from 'react'
// import Phone from "./../../img/phone.png";
export default function WeAre() {
  return (
    <div>
    <div id="about-us" className="about-us-section">
			<div className="container">
				<div className="row">
					
			

					<div className="col-md-7 col-lg-8">
						<div className="about-us-text">
							<div className="section-title relative-position mb20 headline text-left"  >
								<span className="subtitle ml42 text-uppercase ml-5">SORT ABOUT US</span>
								<h2>We are <span title='Indian Technical Computers'>ITC </span>
								work since 2015.</h2>
								<p>We are dedicated to expanding access to quality education in India and specifically in the local area of Firozabad. Our mission is to connect learners with the top universities and institutions in India, empowering them to achieve their educational goals right in their own community.</p>
							</div>
							<div className="about-content-text">
								
								<div className="about-list mb65 ul-li-block "  >
									<ul>
										<li>Professional And Experienced Since 1980</li>
										<li>We Connect Learners To The Best  Universities From Around The World</li>
										<li>Our Mission Increasing Global Access To Quality Aducation</li>
										<li>100K Online Available Courses</li>
									</ul>
								</div>
								{/* <div className="about-btn "  >
									<div className="genius-btn gradient-bg text-center text-uppercase ul-li-block bold-font">
										<span>About Us <i className="fas fa-caret-right"></i></span>
									</div>
									<div className="genius-btn gradient-bg text-center text-uppercase ul-li-block bold-font">
										<span>contact us <i className="fas fa-caret-right"></i></span>
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="col-md-5 col-lg-4">
						<div className="about-resigter-form backgroud-style relative-position">
							<div className="register-content">
								<div className="register-fomr-title text-center">
									<h3 className="bold-font"><span>Get a</span> Free Registration.</h3>
									<p>More Than 122K Online Available Courses</p>
								</div>
								<div className="register-form-area">
									<form className="contact_form" action="#" method="POST" encType="multipart/form-data">
										<div className="contact-info">
											<input className="name" name="name" type="text" placeholder="Your Name." />
										</div>
										<div className="contact-info">
											<input className="nbm" name="nbm" type="number" placeholder="Your Number" />
										</div>
										<div className="contact-info">
											<input className="email" name="email" type="email" placeholder="Email Address." />
										</div>
										<select>
											<option value="9">Select Course.</option>
											<option value="10">Web Design</option>
											<option value="11">Web Development</option>
											<option value="12">Php Core</option>
										</select>
										
										<textarea  placeholder="Message."></textarea>
										<div className="nws-button text-uppercase text-center white text-capitalize">
											<button type="submit" value="Submit">SUBMIT REQUEST </button> 
										</div> 
									</form>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div></div>
  )
}
