import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import Shailendra from './../../img/shailendra_sir_img.webp';
const StudentsSay = () => {
  return (
    <div className='studentSay'>
       <div className='container'>
       <div className="section-title mb-4 headline text-center "  >
					<span className="subtitle text-uppercase">Our Students</span>
					<h2><span>Students</span> Say.</h2>
				</div>
        <div className='row'>
            <div className='col-sm-4'>
                <div className='shailendra'>
                    <img src={Shailendra} alt="" className="img-fluid" />
                </div>
            </div>
            <div className='col-sm-8'>
                <OwlCarousel className='owl-theme studentSlider' items={1} loop margin={10} nav={false}>
                    <div>
                        
                        <div className='studentsDes'>
                           <div className='qouteleft'><i className='fa fa-quote-left'></i></div>
                           <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus.</p>
                           <div className='qouteright'><i className='fa fa-quote-right'></i></div>
                        </div>
                        
                        <div className='studentBox'>
                            <div className='studentsPic'>

                            </div>
                            <div className='studentName'>
                                <h3>Student 1</h3>
                                <p>Basic Computers</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='studentsDes'><p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus.</p></div>
                        <div className='studentBox'>
                            <div className='studentsPic'>

                            </div>
                            <div className='studentName'>
                                <h3>Student 1</h3>
                                <p>Basic Computers</p>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
       </div>
    </div>
  )
}

export default StudentsSay
