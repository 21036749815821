import React,{useState, useEffect} from 'react'
import OwlCarousel from 'react-owl-carousel';
import Adca from "./../../img/adca.webp";
import Bcc from "./../../img/bcc.webp";
import Cca from "./../../img/cca.webp";
import Ccc from "./../../img/ccc.webp";
import Dca from "./../../img/dca.webp";
import Dtp from "./../../img/dtp.webp";
import WebDevelopment from "./../../img/web_development.webp";
import WebDesigining from "./../../img/webdesigning.webp";
const Course = () => {
	const [options, setOptions] = useState({
		margin: 15,
		loop: true,
		autoplay: false,
		responsiveClass: true,
		nav: false,
		dots: true,
		responsive: {
		  0: {
			items: 1.5,
		  },
		  600: {
			items: 3,
		  },
		},
	  });
	
	  useEffect(() => {
		setOptions((prevOptions) => {
		  return {
			...prevOptions,
			responsive: {
			  0: {
				items: 1.5,
			  },
			  600: {
				items: 3,
			  },
			},
		  };
		});
	  }, []);
  return (
    <div>
      <section id="popular-course" className="popular-course-section">
			<div className="container">
				<div className="section-title mb-4 headline text-center "  >
					<span className="subtitle text-uppercase">LEARN NEW SKILLS</span>
					<h2><span>Popular</span> Courses.</h2>
				</div>
				<div className='owlSection'>
				<OwlCarousel className='owl-theme' {...options}>
					<div className="course-item-pic-text" >
						<div className="course-pic">
							<img src={Adca} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>ADCA</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star-o"></i></li>
										<li><i className="fa fa-star-o"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Advanced Diploma in Computer Applications</span> </h3>
							</div>
							{/* <div className="course-viewer ul-li">
								<ul>
									<li><span><i className="fa fa-user"></i> 1.220</span></li>
									<li><span><i className="fa fa-comment-dots"></i> 1.015</span></li>
									<li><span>125k Unrolled</span></li>
								</ul>
							</div> */}
						</div>
					</div>
				
					<div className="course-item-pic-text " >
						<div className="course-pic">
							<img src={Bcc} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>BCC</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star-o"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Basic Computer Course <span className="trend-badge text-uppercase bold-font"><i className="fa fa-bolt"></i> TRENDING</span></span></h3>
							</div>
							
						</div>
					</div>
				

					<div className="course-item-pic-text " >
						<div className="course-pic">
							<img src={Cca} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>CCA</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star-o"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Learning IOS Apps Programming & Development.</span></h3>
							</div>
							
						</div>
					</div>
				
					<div className="course-item-pic-text">
						<div className="course-pic">
							<img src={Ccc} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>CCC</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star-half-o"></i></li>
										<li><i className="fa fa-star-o"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Certificate in Computer Applications</span></h3>
							</div>
							
						</div>
					</div>
					<div className="course-item-pic-text">
						<div className="course-pic">
							<img src={Dca} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>DCA</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Diploma in Computer Application</span></h3>
							</div>
							
						</div>
					</div>

					<div className="course-item-pic-text">
						<div className="course-pic">
							<img src={Dtp} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>DTP</span></span>
								<span className="course-author bold-font"><span>Shailesh Yadav</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Desktop Publishing</span> <span className="trend-badge text-uppercase bold-font"><i className="fa fa-bolt"></i> TRENDING</span></h3>
							</div>
							
						</div>
					</div>
			
					<div className="course-item-pic-text">
						<div className="course-pic">
							<img src={WebDevelopment} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>Web Development</span></span>
								<span className="course-author bold-font"><span>DK</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Fully Programming Web Development.</span> <span className="trend-badge text-uppercase bold-font"><i className="fa fa-bolt"></i> TRENDING</span></h3>
							</div>
							
						</div>
					</div>
                    <div className="course-item-pic-text">
						<div className="course-pic">
							<img src={WebDesigining} alt="" />
						</div>
						<div className="course-item-text">
							<div className="course-meta">
								<span className="course-category bold-font"><span>Web Designing</span></span>
								<span className="course-author bold-font"><span>DK</span></span>
								<div className="course-rate ul-li">
									<ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
									</ul>
								</div>
							</div>
							<div className="course-title mt10 headline pb45 relative-position">
								<h3><span>Fully Responsive Web Designing.</span> <span className="trend-badge text-uppercase bold-font"><i className="fa fa-bolt"></i> TRENDING</span></h3>
							</div>
						</div>
					</div>
			
                    </OwlCarousel>
					</div>
			</div>
		</section>
    </div>
  )
}

export default Course
