import React from 'react'
import Lottie from "lottie-react";
import animationData from './../../img/Json/Why.json';

const WhyChoose = () => {
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    //     rendererSettings: {
    //       preserveAspectRatio: "xMidYMid slice"
    //     }
    //   };
  return (
    <div>
      <section id="why-choose" className="why-choose-section backgroud-style">
			<div className="container">
				<div className="section-title mb-4 headline text-center ">
					<span className="subtitle text-uppercase"><span title='Indian Technical Computers'>ITC</span> ADVANTAGES</span>
					<h2 >Reason <span>Why Choose <span title='Indian Technical Computers'>ITC.</span></span></h2>
				</div>
				<div className="extra-features-content">
					<div className="row">
						<div className="col-lg-4 col-md-7 order-md-2 order-lg-1">
							<div className="extra-left">
								<div className="extra-left-content bg1 c_1">
									<div className="extra-icon-text text-left">
										<div className="features-icon gradient-bg text-center  order-lg-2">
											<i className='fa fa-superpowers'></i>
										</div>
										<div className="features-text padding-left text-right1  order-lg-1">
										    <div className="circle circle1"></div>
											<div className="features-text-title">
												<h3>The Power Of Education.</h3>
											</div>
											<div className="features-text-dec">
												<span>Unlock the power of education with our learning platform </span>
											</div>
										</div>
									</div>
								</div>
								

								<div className="extra-left-content bg2 c_2">
									<div className="extra-icon-text">
										<div className="features-icon gradient-bg text-center  order-lg-2">
										<i className='fa fa-desktop'></i>
										</div>
										<div className="features-text padding-left text-right1 order-lg-1">
										    <div className="circle circle2"></div>
											<div className="features-text-title">
												<h3>Best Online Education.</h3>
											</div>
											<div className="features-text-dec">
												<span>Best Tuition with our online learning platform</span>
											</div>
										</div>
									</div>
								</div>
								

								<div className="extra-left-content bg3 c_3">
									<div className="extra-icon-text">
										<div className="features-icon gradient-bg text-center  order-lg-2">
										   <i className='fa fa-graduation-cap'></i>
										</div>
										<div className="features-text padding-left text-right1 order-lg-1">
										    <div className="circle circle3"></div>
											<div className="features-text-title">
												<h3>Education For Student.</h3>
											</div>
											<div className="features-text-dec">
												<span>Gain knowledge, acquire new skills and comfort of your home</span>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						

						<div className="col-lg-4 col-md-5 order-md-1 order-lg-2">
							<div className=" whychhoseJsonparent ">
								{/* <img src="assets/img/banner/wc-2.png" alt="img" /> */}
                                <div className=" whychhoseJson "><Lottie animationData={animationData} loop={true} /></div>
							</div>
						</div>
						

						<div className="col-lg-4 col-md-7 order-md-3 offset-md-5 order-lg-3 offset-lg-0">
							<div className="extra-left">
								<div className="extra-left-content bg3 c_4">
									<div className="extra-icon-text text-left">
										<div className="features-icon gradient-bg text-center">
											<i className='fa fa-superpowers'></i>
										</div>
										<div className="features-text text-left padding-right">
										    <div className="circle circle4"></div>
											<div className="features-text-title">
												<h3>The Power Of Education.</h3>
											</div>
											<div className="features-text-dec">
												<span>Lorem ipsum dolor sit amet consectuerer adipiscing</span>
											</div>
										</div>
									</div>
								</div>
								

								<div className="extra-left-content bg2 c_5">
									<div className="extra-icon-text">
										<div className="features-icon gradient-bg text-center">
										<i className='fa fa-desktop'></i>
										</div>
										<div className="features-text text-left padding-right">
										    <div className="circle circle5"></div>
											<div className="features-text-title">
												<h3>Best Online Education.</h3>
											</div>
											<div className="features-text-dec">
												<span>Lorem ipsum dolor sit amet consectuerer adipiscing</span>
											</div>
										</div>
									</div>
								</div>
								

								<div className="extra-left-content bg1 c_6">
									<div className="extra-icon-text">
										<div className="features-icon gradient-bg text-center">
										<i className='fa fa-graduation-cap'></i>
										</div>
										<div className="features-text text-left padding-right">
										<div className="circle circle6"></div>
											<div className="features-text-title">
												<h3>Education For Student.</h3>
											</div>
											<div className="features-text-dec">
												<span>Lorem ipsum dolor sit amet consectuerer adipiscing</span>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default WhyChoose
