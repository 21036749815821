import React from 'react'

// import Lottie from "lottie-react";
// import AboutJson from "./../../img/Json/about.json";
import Breadcum from './../../Components/Breadcum/Breadcum';
import A from '../../Components/Link/A';
const About = () => {
    const arrotag = (       
        <> Know More <span><i className="fa fa-arrow-right"></i></span></>
    )
  return (
    <div>
              
<div className="otherpage">
    <div className="container">
        <h1 className="title--h1 text-center"><span>Who</span> We Are?</h1>
        <Breadcum path='/' name="Home" pagename="About Us" />
        <div className="otherPageContent">
        <p className="text-justify"><strong title='Indian Technical Computers'>Indian Technical Computers</strong> is a leading computer training center located in Shikohabad, District Firozabad, Uttar Pradesh. We offer a wide range of courses and programs designed to equip individuals with the skills and knowledge required to succeed in the ever-evolving field of computer technology. From beginners to advanced level, we provide comprehensive training that caters to the diverse needs of our students.</p>
       </div>
   </div>
   <div className="sectionKnowabout">
      <div className="container">
       <div className="row">
           <div className="col-sm-12">
               <div className="aboutLeftContent">
                   <h2 className="title--h2">Know About <span title='Indian Technical Computers'>Indian Technical Computers</span> Platform</h2>
                   <div className="knowAboutBox">
                       <div className="knowBoxIcon">
                           <i className="fa fa-laptop"></i>
                       </div>
                       <div className="knowBoxText">
                           <h3 className="title--h3">Modernized Teaching Approach</h3>
                           <p>Our platform embraces a modernized teaching approach, leveraging innovative techniques and technologies to enhance the learning experience. Our tutors are well-versed in utilizing digital tools and interactive resources to make lessons engaging and interactive.</p>
                       </div>
                   </div>
                   <div className="knowAboutBox">
                       <div className="knowBoxIcon">
                           <i className="fa fa-heart"></i>
                       </div>
                       <div className="knowBoxText">
                           <h3 className="title--h3">Customized Learning Solutions</h3>
                           <p>We understand that every student has unique learning needs. Our platform offers personalized tuition that is tailored to the individual requirements of each student. Through comprehensive assessments and ongoing feedback, our tutors adapt their teaching methods to ensure optimal learning outcomes.</p>
                       </div>
                   </div>
                   <div className="knowAboutBox">
                       <div className="knowBoxIcon">
                           <i className="fa fa-book"></i>
                       </div>
                       <div className="knowBoxText">
                           <h3 className="title--h3">Expert Educators from Global Talent Pool</h3>
                           <p>We have curated a diverse community of expert educators from around the globe. Our platform connects students with highly experienced and qualified tutors who bring a wealth of knowledge and cultural perspectives to the table. Students can benefit from exposure to different teaching styles and gain a global perspective on their subjects of study.</p>
                       </div>
                   </div>
                   <div className="btn btn-modern"><A  to="/" name={arrotag} /></div>
               </div>
           </div>
           {/* <div className="col-sm-5">
               <div id="about">
               <Lottie animationData={AboutJson} loop={true} />
               </div>
           </div> */}
       </div>
    </div>
</div>
<div className="ourvision">
    <div className="container">
        <div>
            <h2 className="title--h2 mb-3">Mission</h2>
            <p>Our mission is to empower individuals with the necessary computer skills and expertise to thrive in today's digital world. We strive to deliver high-quality training programs that combine theoretical knowledge with practical hands-on experience. By providing a conducive learning environment and employing experienced instructors, we aim to foster a passion for learning and enable our students to achieve their full potential.</p><br />
        </div>
        <div>
            <h2 className="title--h2 mb-3">Vision</h2>
            <p>Our vision is to be a recognized leader in computer education, known for our commitment to excellence and the success of our students. We envision a future where individuals from all walks of life have access to affordable and quality computer training, enabling them to enhance their career prospects and contribute effectively to the digital economy. We aspire to continually adapt and innovate our programs to stay at the forefront of technology advancements and meet the evolving needs of the industry.
</p><p>
At Indian Technical Computers, we are dedicated to delivering top-notch computer training, promoting lifelong learning, and helping individuals become skilled professionals in the field of computer technology. Join us on this exciting journey and unlock your potential for a successful career in the digital world.</p><br />
        </div>
    </div>
</div>
</div>  
    </div>
  )
}

export default About