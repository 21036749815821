import React from 'react'
import Lottie from "lottie-react";
import animationData from './../../img/Json/banner_1.json';
// import banner1 from './../../img/bannertxt.webp';
import banner2 from './../../img/banner2.png';
const Banner = () => {
  return (
    <div className='banner'>
       <div className='container'>
        <div className='row'>
            <div className='col-sm-7 order-sm-2'>
               <div className="bannerimg"><Lottie animationData={animationData} loop={true} /></div>
            </div>
            <div className='col-sm-5 order-sm-1'>
                <div  className='bannerStudy'>
                    <span>EXCELLENCE IN COMPUTERS</span>
                    <h1>Empowering Minds, Building a Better Learning Future</h1>
                    {/* <p>Learning is a life-long journey that in fact we never find the terminate stop. Stop searching, enjoy the process.</p> */}
                    {/* <img src={banner1} alt="" className="img-fluid" /> */}
                </div>
                <div className='row'>
                    <div className='col-sm-4 col-5'>
                        <div>
                          <img src={banner2} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className='col-sm-8 col-7'>
                        <div className='bannerFeature'>
                           <ul>
                              <li>2 day free trial tuitions</li>
                              <li>Free Books</li>
                              <li>Free Complete Guidance</li>
                              <li>Throughout Scholarship process</li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
       </div>
      
    </div>
  )
}

export default Banner
