import React from 'react'

const Feature = () => {
  return (
    <div>
      <section id="search-course" className="search-course-section">
			<div className="container">
				<div className="section-title mb20 headline text-center mb-4"  >
					<span className="subtitle text-uppercase">SMART LEARNING</span>
					<h2><span>Indian Technical</span>  Computers.</h2>
				</div>
				<div className="search-counter-up">
					<div className="row">
						<div className="col-md-3 col-sm-6 col-6">
							<div className="counter-icon-number "  >
								<div className="counter-icon">
									<i className='fa fa-graduation-cap'></i>
								</div>
								<div className="counter-number">
									<span className="counter-count bold-font">5 </span><span>M+</span>
									<p>Students Enrolled</p>
								</div>
							</div>
						</div>
					

						<div className="col-md-3 col-sm-6 col-6">
							<div className="counter-icon-number " >
								<div className="counter-icon">
								<i className='fa fa-mobile'></i>
								</div>
								<div className="counter-number">
									<span className="counter-count bold-font">122</span><span>.500+</span>
									<p>Online Available Courses</p>
								</div>
							</div>
						</div>
					

						<div className="col-md-3 col-sm-6 col-6">
							<div className="counter-icon-number " >
								<div className="counter-icon">
								  <i className='fa fa-book'></i>
								</div>
								<div className="counter-number">
									<span className="counter-count bold-font">15</span><span>.000+</span>
									<p>Quality Courses</p>
								</div>
							</div>
						</div>
						
						<div className="col-md-3 col-sm-6 col-6">
							<div className="counter-icon-number " >
								<div className="counter-icon">
									<i className="fa fa-group"></i>
								</div>
								<div className="counter-number">
									<span className="counter-count bold-font">7</span><span>.500+</span>
									<p>Teachers Registered</p>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</section> 
    </div>
  )
}

export default Feature
