function MarksheetPage(props){
    //console.log(props);
   const {title, roll, com,ms,accounting,dtp,it} = props.data
   const {so,course,birth, image} = props.data.other
   const sum = com + ms + accounting + dtp + it;
   console.log(props.data)
   return(
    <>
    <div style={{position:"absolute", left:"150px", top:"280px"}}> {roll}</div>
    <div style={{position:"absolute", right:"95px", top:"248px", width:"90px"}}> <img src={`data:image/webp;base64,${image}`}  alt='' className='img-fluid' /></div>

    
    <table cellPadding={1} cellSpacing={0} style={{position:"absolute", top:"321px", left:"210px"}}>
    <tr>
            <td>{title}</td>
        </tr>
        <tr>
            <td>{so}</td>
        </tr>
       
        <tr>
            <td>{birth}</td>
        </tr>
        <tr>
            <td>{course}</td>
        </tr>
        
        <tr>
            <td style={{
                textTransform:"uppercase"
            }}>Indian Technical Computers Education Society</td>
        </tr>
    </table>
    <table width={240} cellSpacing={0} cellPadding={6} style={{position:"absolute", top:"526px", left:"430px"}}>
        <tr>
        <td>
        {com}
        </td>
        <td>
        {com}
            </td>
        </tr>
        <tr>
        <td>
        {ms}
        </td>
        <td>
        {ms}
            </td>
        </tr>
        <tr>
        <td>
        {accounting}
        </td>
        <td>
        {accounting}
            </td>
        </tr>
        <tr>
        <td>
        {dtp}
        </td>
        <td>
        {dtp}
            </td>
        </tr>
        <tr>
        <td>
        {it}
        </td>
        <td>
        {it}
        </td>
        </tr>
        <tr>
            <td></td>
            <td style={{padding:"2px"}}>{sum}</td>
        </tr>
    </table>
         
    </>
   )
}
export default MarksheetPage;