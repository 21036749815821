import React from 'react'
import A from '../Link/A';
import Logo from './../../img/logo_animation.gif'; 

const Footer = () => {
  return (
    <div>
      <footer>
   	  <div className="container">
   	  	<div className="row">
   	  	 <div className="col-sm-4">
   	  	 	 <div className="footerDes">
   	  	 	 	<div className="footerlogo">
   	  	 	 		<img src={Logo} className="img-fluid" alt='' />
   	  	 	 	</div>
   	  	 	 	<div className="footertxt">
   	  	 	 		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
   	  	 	 	</div>
   	  	 	 </div>
   	  	 </div>
   	  	 <div className="col-sm-7 offset-sm-1">
   	  	 	<div className="row">
   	  	 		<div className="col-sm-4">
   	  	 			<div className="footermenu">
   	  	 				<ul>
   	  	 					<li><A to="/" name="Careers">Careers</A></li>
   	  	 					<li><A to="/about-us" name="About us"></A></li>
   	  	 					<li><A to="/contact-us" name="Contact us"></A></li>
   	  	 					<li><A to="/courses" name="Courses">Courses</A></li>
   	  	 					<li><A to="/" name="Blogs">Blogs</A></li>
   	  	 				</ul>
   	  	 			</div>
   	  	 		</div>
   	  	 		<div className="col-sm-8">
   	  	 			<div className="footerSub">
   	  	 				<h2>Get all the latest updates</h2>
   	  	 				<div className="subform">
   	  	 					<div className="sub_left"><input placeholder="Enter You email Addrress" /></div>
   	  	 					<div className="sub_right"><button>Subscribe</button></div>
   	  	 				</div>
   	  	 				<div className="socialMedia">
   	  	 					<ul>
   	  	 						<li>Follow us</li>
   	  	 						<li><A to="/" name={<i className="fa fa-facebook"></i>}></A></li>
								<li><A to="/" name={<i className="fa fa-instagram"></i>}></A></li>
								<li><A to="/" name={<i className="fa fa-linkedin"></i>}></A></li>
								<li><A to="/" name={<i className="fa fa-youtube"></i>}></A></li>
   	  	 					</ul>
   	  	 				</div>
   	  	 			</div>
   	  	 		</div>
   	  	 	</div>
   	  	 </div>
   	  </div>
   	</div>
   </footer>
   <div className="copyright">
   	  <div className="container">
	   	  	<div className="row">
	   	  		<div className="col-sm-7">
	   	  			<p>&copy; indiantechnicalcomputers.com. All rights reserved.</p>
	   	  		</div>
	   	  		<div className="col-sm-5">
	   	  			<div className="copymenu">
	   	  				<ul>
	   	  					<li><A to="/" name="Disclaimer" /></li>
	   	  					<li><A to="/" name="Privacy Policy" /></li>
	   	  				</ul>
	   	  			</div>
	   	  		</div>
	   	  	</div>
   	   </div>
   </div>
    </div>
  )
}

export default Footer
