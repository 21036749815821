import React from 'react'
import Lottie from "lottie-react";
import ContactJson from "./../../img/Json/contact.json";
import Breadcum from '../../Components/Breadcum/Breadcum'
const Contact = () => {
  return (
    <div>
      <div className="otherpage">
    <div className="container">
        <h1 className="title--h1 text-center"><span>Get</span> In Touch</h1>
        <Breadcum path='/' name="Home" pagename="Contact Us" />
        <div className="contactimg" id="contactimg">
        <Lottie animationData={ContactJson} loop={true} />
        </div>
   </div>
   <div className="contact_section">
       <div className="container">
           <div className="row">
               <div className="col-sm-8">
                   <div className="contentForm">
                       <div className="fill_it">Fill The Form</div>
                       <div className="contentFormArea">
                        <form method="" id="contactForm">
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-6">
                                       <label>Name</label>
                                       <input type="text" name="name" />
                                   </div>
                                   <div className="col-sm-6">
                                       <label>Email</label>
                                       <input type="email" name="email" />
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <label>Subject</label>
                                       <input type="subject" name="subject" />
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <label>Message</label>
                                       <textarea name="message" rows="5"></textarea>
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <button type="submit"id="btn-submit" name="contactForm">Submit Request</button>
                                   </div>
                               </div>
                           </div>
                           <div id="error"></div>
                           </form>
                       </div>
                   </div>
               </div>
               <div className="col-sm-4">
                   <div className="contentForm">
                       <div className="fill_it">Reach Us</div>
                       <div className="contentFormArea">
                           <h2>Get In Touch</h2>
                           <p>We'll Respond in couple of time</p>
                           <ul>
                          <li><span><i className="fa fa-home"></i></span> Shikohabad, Uttar Pradesh</li>
                          <li><span><i className="fa fa-phone" ></i></span> +9012 342 187</li>
                          <li><span><i className="fa fa-envelope-o" ></i></span> @support</li>
                        </ul>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>     

    </div>
  )
}

export default Contact