import React, { useState, useRef } from 'react';
import axios from 'axios';
import Lottie from "lottie-react";
import animationData from './../../img/Json/certificate.json';
import Cer_20_23 from "./../../img/q1.jpg";
import Cer_17_20 from "./../../img/q3.jpg";
import Cer_23_25 from "./../../img/q2.jpg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';
function isDateInRange(date, startDate, endDate) {
  const parsedDate = new Date(date);
  const parsedStartDate = new Date(startDate);
  const parsedEndDate = new Date(endDate);
  
  return parsedDate >= parsedStartDate && parsedDate <= parsedEndDate;
}
// const formatDate = (date) => {
//   const [day, month, year] = date.split('/');
//   return `${year}/${month}/${day}`;
// };

const Certificate = () => {
  const downloadBoxRef = useRef(null);
  const [studentId, setStudentId] = useState('');
  const [studentDetails, setStudentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;

    // Use navigate function to navigate to the selected route
    if (selectedValue === 'Marksheet') {
      navigate('/marksheet');
    } else if (selectedValue === 'Certificate') {
      navigate('/certificate');
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    if (downloadBoxRef.current) {
      downloadBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setIsLoading(true);
    // Make the API request to fetch student details
    axios.get(`https://admin.indiantechnicalcomputers.com/wp-json/get/v1/students/${studentId}`)
      .then(response => {
        setStudentDetails(response.data);
        setError('');
        
      })
      .catch(error => {
        setStudentDetails(null);
        setError('Failed to fetch student details');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  
  const handleDownloadPDF = () => {
    // Get the certificate element by its class or ID
    const certificateElement = document.querySelector('.certificatebox');

    // Create a canvas from the certificate element
    html2canvas(certificateElement).then(canvas => {
      // Convert the canvas to an image data URL
      const imageData = canvas.toDataURL('image/png');

      // Initialize a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Calculate the dimensions of the PDF page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add the image data to the PDF
      pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Save the PDF file
      pdf.save('certificate.pdf');
    });
  };

  // Add this part to format the issue date for comparison
  const formattedIssueDate = studentDetails?.issue
    ? `${studentDetails.issue.split('/').reverse().join('/')}`
    : '';
  return (
    <div className='otherpage'>
      <div className='container'>
        <div className="section-title mb-4 headline text-center">
          <span className="subtitle text-uppercase">Download</span>
          <h2><span>Download</span> Certificate.</h2>
        </div>
        <div className="hero">
          <div className="row">
            <div className='col-sm-6'>
              <div className="hero-text">
                <h2>Student Certificate Download</h2>
                <p>To download your student certificate, please provide the following information.</p>
              </div>
              <div>
      <label className='text-white'>
        <input type="radio"  value="Marksheet" onChange={handleRadioChange} />
        &nbsp; Marksheet
      </label>
      &nbsp;&nbsp;&nbsp;
      <label className='text-white'>
        <input type="radio" checked value="Certificate" onChange={handleRadioChange} />
        &nbsp; Certificate
      </label>
    </div>
              <form onSubmit={handleSubmit}>
                <div className="hero-form-input">
                  <input className="hero-email-input" type="text" value={studentId} onChange={e => setStudentId(e.target.value)} placeholder="Enter Student Roll Number" />
                  <button type='submit'>Submit</button>
                </div>
              </form>
            </div>
            <div className='col-sm-6'>
              <div className="hero-image">
                <Lottie animationData={animationData} loop={true} />
              </div>
            </div>
          </div>
        </div>
        <div className='downloadbox' ref={downloadBoxRef}>
          {isLoading && (
            <div>
              <div className="flexbox">
                <div>
                  <div className="triple-spinner"></div>
                </div>
              </div>
              <p>Loading...</p>
            </div>
          )}
          {error && <p>{error}</p>}
          {studentDetails && (
            <div className='certificate'>
              <div className='certificateheading'>
               <h2>Student Details</h2>
                <button onClick={handleDownloadPDF}>Download PDF</button>
              </div>
              <div className='row justify-content-md-center'>
                <div className='certisection'>
                  <div className='certificatebox'>
                    <div className='s_image'><img src={studentDetails.image} alt='' className='img-fluid' /></div>
                    <div className='s_name'>{studentDetails.title}</div>
                    <div className='s_roll'>{studentDetails.roll}</div>
                    <div className='s_so'> {studentDetails.so}</div>
                    <div className='s_birth'>{studentDetails.birth}</div>
                    <div className='s_start'>{studentDetails.course}</div>
                    <div className='s_end'>{studentDetails.during}</div>
                    <div className='s_session'>{studentDetails.session}</div>
                    <div className='s_pass'>{studentDetails.pass}</div>
                    <div className='s_institute'>Indian Technical Computer </div>
                    <div className='s_issue'>{studentDetails.issue}</div> 
               
                    {/* Conditionally render the image based on the date range */}
                    {formattedIssueDate && (
                      <>
                        {isDateInRange(formattedIssueDate, "2017/02/01", "2020/01/31") && (
                          <img src={Cer_17_20} alt="" />
                        )}
                        {isDateInRange(formattedIssueDate, "2020/01/02", "2022/01/03") && (
                          <img src={Cer_20_23} alt="" />
                        )}
                        {isDateInRange(formattedIssueDate, "2022/06/20", "2025/12/26") && (
                          <img src={Cer_23_25} alt="" />
                        )}
                      </>
                    )}
                    {/* ... rest of your component ... */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Certificate;