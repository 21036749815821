import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from "./Pages/About/About"
import Course from './Pages/Home/Course';
import Contact from './Pages/Contact/Contact'
import ScrollToTop from './Pages/ScrollTop/ScrollToTop'
import Certificate from './Pages/Certificate/Certificate';
import Gallery from './Pages/Gallery/Gallery';
import Marksheet from './Pages/Certificate/Marksheet';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
         <ScrollToTop /> 
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/courses" element={<Course />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/certificate" element={<Certificate />} />
              <Route path="/marksheet" element={<Marksheet />} />
              <Route path="/gallery" element={<Gallery />} />
               {/*<Route path="/request-a-tutor" element={<RequestAtutor />} />
              <Route path="/blogs" element={<BlogHome />} />
              <Route path="/login" element={<Login />} /> */}
            </Routes>
            <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
