import React, { useState, useRef } from 'react';
import axios from 'axios';
import Lottie from "lottie-react";
import animationData from './../../img/Json/certificate.json';
import Cer_1 from "./../../img/marksheet.webp";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MarksheetPage from './marksheet_page';
import { useNavigate } from 'react-router-dom';

const Certificate = () => {
  
  const downloadBoxRef = useRef(null);
  const [studentId, setStudentId] = useState('');
  const [studentDetails, setStudentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;

    // Use navigate function to navigate to the selected route
    if (selectedValue === 'Marksheet') {
      navigate('/marksheet');
    } else if (selectedValue === 'Certificate') {
      navigate('/certificate');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (downloadBoxRef.current) {
        downloadBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

    setIsLoading(true);
    // Make the API request to fetch student details
    axios.get(`https://admin.indiantechnicalcomputers.com/wp-json/get/v1/marksheet/${studentId}`)
      .then(response => {
        setStudentDetails(response.data);
        setError('');
      })
      .catch(error => {
        setStudentDetails(null);
        setError('Failed to fetch student details');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  


  const handleDownloadPDF = () => {
    // Get the certificate element by its class or ID
    const certificateElement = document.querySelector('.certificatebox');

    // Create a canvas from the certificate element
    html2canvas(certificateElement).then(canvas => {
      // Convert the canvas to an image data URL
      const imageData = canvas.toDataURL('image/png');

      // Initialize a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Calculate the dimensions of the PDF page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add the image data to the PDF
      pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Save the PDF file
      pdf.save('certificate.pdf');
    });
  };

  return (
    <div className='otherpage'>
      <div className='container'>
        <div className="section-title mb-4 headline text-center">
          <span className="subtitle text-uppercase">Download</span>
          <h2><span>Download</span> Certificate.</h2>
        </div>
        <div className="hero">
          <div className="row">
            <div className='col-sm-6'>
              <div className="hero-text">
                <h2>Student Certificate Download</h2>
                <p>To download your student certificate, please provide the following information.</p>
              </div>
              <div>
      <label className='text-white'>
        <input type="radio" checked value="Marksheet" onChange={handleRadioChange} />
        &nbsp; Marksheet
      </label>
      &nbsp;&nbsp;&nbsp;
      <label className='text-white'>
        <input type="radio" value="Certificate" onChange={handleRadioChange} />
        &nbsp; Certificate
      </label>
    </div>
              <form onSubmit={handleSubmit}>
                <div className="hero-form-input">
                  <input className="hero-email-input" type="text" value={studentId} onChange={e => setStudentId(e.target.value)} placeholder="Enter Student Roll Number" />
                  <button type='submit'>Submit</button>
                </div>
              </form>
            </div>
            <div className='col-sm-6'>
              <div className="hero-image">
                <Lottie animationData={animationData} loop={true} />
              </div>
            </div>
          </div>
        </div>
        <div className='downloadbox' ref={downloadBoxRef}>
          {isLoading && (
            <div>
              <div className="flexbox">
                <div>
                  <div className="triple-spinner"></div>
                </div>
              </div>
              <p>Loading...</p>
            </div>
          )}
          {error && <p>{error}</p>}
          {studentDetails && (
             <div className='certificate'>
             <div className='certificateheading'>
              <h2>Student Details</h2>
               <button onClick={handleDownloadPDF}>Download PDF</button>
             </div>
             <div className='row justify-content-md-center'>
               <div className='certisection'>
                 <div className='certificatebox'>
                 {/* <div  style={{position:"absolute", left:"545px", top:"250px", height:110, width:90, zIndex:1, background:`url(data:image/webp;base64,${studentDetails.other.image}) center top`}}></div> */}
            <MarksheetPage data={studentDetails} />
            <img src={Cer_1} alt="" />  
            </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Certificate;